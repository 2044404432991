import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Mic, AudioLines, Globe } from 'lucide-react';
import VoiceChatFeature from './components/VoiceChatFeature';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './auth/context/AuthContext';
import { useAuth } from './auth/context/AuthContext';
import GoogleLoginButton from './auth/components/GoogleLoginButton';
import AuthGuard from './auth/components/AuthGuard';
import PricingPage from './components/PricingPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './config/firebase';

// Componente da página inicial
const LandingPage = ({ onStart }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      if (user) {
        const userRef = doc(db, 'usuarios', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setCredits(userDoc.data().credits || 0);
        }
      }
    };

    fetchCredits();
  }, [user]);

  const handleStartClick = () => {
    if (!user) {
      alert("Veuillez vous connecter avec Google pour continuer");
      return;
    }
    onStart();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a237e] via-[#0d47a1] to-[#01579b] relative overflow-hidden">
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-96 h-96 bg-blue-400 rounded-full filter blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-indigo-400 rounded-full filter blur-3xl"></div>
      </div>
      <div className="relative z-10 container mx-auto px-4 py-20 text-center">
        <h1 className="text-4xl font-bold text-white mb-2">
          Débloquez votre Français 
        </h1>
        <div className="flex items-center justify-center gap-2 mb-4">
          <span className="h-1 w-8 bg-orange-400"></span>
          <p className="text-orange-300">Essai gratuit • Sans carte bancaire</p>
          <span className="h-1 w-8 bg-orange-400"></span>
        </div>
        <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
          Ici, on parle exclusivement français. Pas besoin de 50 langues, concentrez-vous sur ce qui compte : le français.
        </p>
        <div className="flex flex-col items-center space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Mic className="w-12 h-12 text-orange-400 mx-auto mb-4" aria-label="Microphone pour pratique orale" />
              <h3 className="text-lg font-semibold text-white mb-2">Pratique Immédiate</h3>
              <p className="text-blue-100">Parlez avec notre Guide IA interactif</p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <AudioLines className="w-12 h-12 text-orange-400 mx-auto mb-4" aria-label="Corrections audio en temps réel" />
              <h3 className="text-lg font-semibold text-white mb-2">Corrections Instantanées</h3>
              <p className="text-blue-100">Recevez des corrections et des explications</p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Globe className="w-12 h-12 text-orange-400 mx-auto mb-4" aria-label="Découverte de la culture française" />
              <h3 className="text-lg font-semibold text-white mb-2">Découverte Culturelle</h3>
              <p className="text-blue-100">Apprenez des curiosités sur la culture française</p>
            </div>
          </div>

          <div className="flex flex-col items-center space-y-4">
            <GoogleLoginButton onSuccess={onStart} className="bg-transparent border border-white/20 text-white hover:bg-white/10" />
          </div>

          <button
            onClick={handleStartClick}
            className="bg-orange-500 hover:bg-orange-600 text-white text-lg font-semibold px-8 py-4 rounded-full transition-all transform hover:scale-105 shadow-lg"
          >
            Commencer l'enregistrement
          </button>

          {user && (
            <p className="text-white/60 text-xs mt-2">
              {user.email} - {credits} crédits disponibles
            </p>
          )}
        </div>

        {/* FAQ Section */}
        <div className="mt-16 max-w-3xl mx-auto text-white text-left">
          <h2 className="text-3xl font-bold mb-6 text-center">Questions Fréquentes</h2>
          <div className="space-y-4">
            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">1. Comment fonctionne FrenchAudioChat ?</summary>
              <p className="mt-2 text-blue-100">
                FrenchAudioChat est un <strong>web app</strong> simple et pratique. Vous parlez directement dans le micro depuis votre ordinateur ou appareil Android. L'IA transcrit vos mots, corrige vos erreurs avec des explications claires et ajoute des conseils culturels pour enrichir votre apprentissage.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">2. C'est vraiment gratuit au début ?</summary>
              <p className="mt-2 text-blue-100">
                Oui, vous pouvez tester gratuitement notre méthode, sans carte de crédit. Cela vous permet d'essayer toutes les fonctionnalités avant d'acheter des crédits.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">3. Y a-t-il un abonnement ?</summary>
              <p className="mt-2 text-blue-100">
                Non. Vous achetez des crédits à votre rythme, selon vos besoins. Chaque crédit correspond à une interaction avec l'IA.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">4. Sur quels appareils puis-je l'utiliser ?</summary>
              <p className="mt-2 text-blue-100">
                FrenchAudioChat est un <strong>web app</strong>, ce qui signifie qu'il fonctionne directement dans votre navigateur, sur ordinateur ou appareil Android. Aucune installation n'est nécessaire.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">5. Pourquoi choisir FrenchAudioChat ?</summary>
              <p className="mt-2 text-blue-100">
                Contrairement aux autres apps, nous nous concentrons uniquement sur le français. Vous recevez des corrections, des explications et des conseils culturels en temps réel, comme si vous aviez un coach personnel.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">6. Comment acheter des crédits ?</summary>
              <p className="mt-2 text-blue-100">
                Vous pouvez acheter des crédits directement sur notre plateforme en ligne. Aucune souscription n'est requise, vous payez uniquement ce dont vous avez besoin.
              </p>
            </details>

            <details className="bg-white/10 backdrop-blur-lg p-4 rounded-lg">
              <summary className="text-lg font-semibold cursor-pointer">7. À quoi servent les conseils culturels ?</summary>
              <p className="mt-2 text-blue-100">
                Les conseils culturels vous aident à comprendre les nuances du français : expressions, traditions et contexte local. Cela rend votre apprentissage plus naturel et immersif.
              </p>
            </details>
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-2 border-t border-white/10">
        <p className="text-[10px] text-center text-white/60">
           Version actuelle : L'application fonctionne. Donnez-nous votre avis pour l'améliorer ! Merci. Email: <a href="mailto:frenchaudiochat@gmail.com" className="hover:underline">frenchaudiochat@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

// Componente interno que usa os hooks
const AppContent = () => {
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (!user) {
      return;
    }
    setStarted(true);
    navigate('/chat');
  };

  if (!started) {
    return <LandingPage onStart={handleStart} />;
  }

  return (
    <AuthGuard>
      <Routes>
        <Route path="/chat" element={<VoiceChatFeature />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="*" element={<Navigate to="/chat" replace />} />
      </Routes>
    </AuthGuard>
  );
};

// Componente principal que configura os providers
const App = () => (
  <Router>
    <GoogleOAuthProvider clientId="1083924288116-aj1kpresu1qmjrpe1qldv19eod738nke.apps.googleusercontent.com">
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </GoogleOAuthProvider>
  </Router>
);

export default App;
